<template>
  <section title="">
    <b-card>
      <b-row>
        <vue-confirm-dialog />
        <b-col
          cols="12"
        >
          <b-card-body>
            <b-row class="mb-1">
              <b-col
                md="9"
              />
              <b-col md="3" />
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Ações'">
                      <b-button
                        v-b-modal.popupEditar
                        variant="info"
                        class="btn-icon rounded-circle"
                        @click="EditarProjeto(item)"
                      >
                        <feather-icon icon="Edit3Icon" />
                      </b-button>
                      <b-button
                        variant="danger"
                        class="btn-icon rounded-circle"
                        @click="DeletarDados(item)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'status'">
                      <b-badge
                        :variant="NomeStatus(item).cor"
                      >
                        {{ NomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="popupEditar"
      title="Editar Atividade"
      hide-footer
      centered
      size="lg"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Selecione o cliente :"
            label-for="cliente"
          >
            <v-select
              v-model="cliente"
              label="name"
              placeholder="Cliente"
              :options="opcoescliente"
              @input="buscarContrato()"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Selecione o Contrato :"
            label-for="contrato"
          >
            <v-select
              v-model="contrato"
              label="name"
              placeholder="Contrato"
              :options="opcoescontrato"
              :disabled="!cliente"
              @input="BuscarObra()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-group
            label="Selecione a Obra :"
            label-for="obra"
          >
            <v-select
              v-model="obra"
              label="name"
              placeholder="Obra"
              :options="opcoesobra"
              :disabled="!contrato || !cliente"
              @input="BuscarDisciplina()"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Selecione a Disciplina :"
            label-for="disciplina"
          >
            <v-select
              v-model="disciplina"
              label="name"
              placeholder="Disciplina"
              :options="opcoesdisciplina"
              :disabled="!contrato || !cliente || !obra"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Selecione a Atividade :"
            label-for="atividade"
          >
            <v-select
              v-model="atividade"
              label="name"
              placeholder="Atividade"
              :options="opcoesatividade"
              :disabled="!contrato || !cliente || !obra || !disciplina"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            block
            @click="SalvarCliente()"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>

import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexContratoLista',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      // ------------- DADOS TABELA -------- \\
      url: 'admin/atividade',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'atividade',
          name: 'atividade.nome',
          orderable: true,
        },
        {
          label: 'obra',
          name: 'obra.nome',
          orderable: true,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
      // -------------------------- Dados Banco -------------------------- \\
      id: null,
      cliente: null,
      opcoescliente: [],
      contrato: null,
      opcoescontrato: [],
      obra: null,
      opcoesobra: [],
      disciplina: null,
      opcoesdisciplina: [],
      atividade: null,
      opcoesatividade: [],
    }
  },
  watch: {
    // cliente(Q, W) {
    //   if (W) this.contrato = null
    // },
    // contrato(M, B) {
    //   if (B) this.obra = null
    // },
    // obra(C, V) {
    //   if (V) this.disciplina = null
    // },
    // disciplina(Z, X) {
    //   if (X) this.atividade = null
    // },
  },
  async created() {
    this.BuscarDados(this.url)
    await this.$http.get('admin/clientes_ver').then(cliente => {
      this.opcoescliente = cliente.data
    })
    await this.$http.get('admin/atividade_categoria').then(atividadecatR => {
      const atividadeBancoR = atividadecatR.data.data
      const atividadecat = []
      atividadeBancoR.forEach(atv => {
        atividadecat.push({
          value: atv.id,
          name: atv.nome,
        })
      })
      this.opcoesatividade = atividadecat
    })
  },
  methods: {
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
    },
    async buscarContrato() {
      if (this.cliente === null) return
      await this.$http.get(`admin/contrato_ver/${this.cliente.value}`).then(contrato => {
        this.opcoescontrato = contrato.data
      })
    },
    async BuscarObra() {
      if (this.contrato === null) return
      await this.$http.get(`admin/obra_ver/${this.contrato.value}`).then(obra => {
        this.opcoesobra = obra.data
      })
    },
    async BuscarDisciplina() {
      if (this.obra === null) return
      await this.$http.get(`admin/disciplina_ver/${this.obra.value}`).then(disciplina => {
        this.opcoesdisciplina = disciplina.data
      })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    LimparCampos() {
      this.id = null
      this.cliente = null
      this.contrato = null
      this.obra = null
      this.disciplina = null
      this.atividade = null
    },
    SalvarCliente() {
      const obj = {
        cliente_id: this.cliente.value,
        contrato_id: this.contrato.value,
        obra_id: this.obra.value,
        disciplina_id: this.disciplina.value,
        atividade_id: this.atividade.value,
      }
      // --------------------- FUNÇÃO DE EDITAR ---------------------------- //
      this.$http.put(`admin/atividade/${this.id}`, obj).then(resp => {
        if (resp.data.success) {
          this.Notificacao('success', resp.data.success)
        }
        if (resp.data.error) {
          this.Notificacao('danger', resp.data.error)
        }
        this.$bvModal.hide('popupEditar')
        this.reloadTable()
      })
    },
    EditarProjeto(item) {
      localStorage.setItem('editatividades', JSON.stringify(item))
      const atividades = JSON.parse(localStorage.getItem('editatividades'))
      this.id = atividades.id
      this.cliente = atividades.cliente.nome
      this.contrato = atividades.contrato.numero_contrato
      this.obra = atividades.obra.nome
      this.disciplina = atividades.disciplina.nome
      this.atividade = atividades.atividade.nome
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Excluir?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`admin/atividade/${item.id}`)
              this.Notificacao('success', 'Disciplina Removida com sucesso')
              this.reloadTable()
            }
          },
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
